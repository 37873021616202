// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require.context('../images', true, /\.(gif|jpg|png|svg|webm|mp4)$/i)
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Third party
import $ from 'jquery';
global.$ = $;
global.jQuery = $;
require("select2")


import './scss/styles.scss'
import 'select2/dist/css/select2.min.css'


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


document.addEventListener('DOMContentLoaded', () => {
    (document.querySelectorAll('.notification-flash .delete') || []).forEach(($delete) => {
        const $notification = $delete.parentNode;

        $delete.addEventListener('click', () => {
            $notification.parentNode.removeChild($notification);
        });
    });
});

$(document).on('turbolinks:load', function(){
    $(".notification-flash").delay(5000).slideUp(500);

    $(window).scroll(function() {

        var scroll = $(window).scrollTop();
        if (scroll >= 40) {
            $(".js-navbar").removeClass("is-transparent-pr").removeClass("is-primary").addClass("is-light");
            $('.js-demo').removeClass('is-white').addClass('is-primary');
        } else {
            $(".js-navbar").addClass("is-transparent-pr").addClass("is-primary").removeClass("is-light");
            $('.js-demo').removeClass('is-primary').addClass('is-white');
        }
    })

    $(".navbar-burger").click(function() {

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        $(".navbar-burger").toggleClass("is-active");
        $(".navbar-menu").toggleClass("is-active");

    });
});